<template>
  <div class="summary-table">
    <b-modal hide-header no-close-on-esc no-close-on-backdrop ref="alert-cas" centered hide-footer>
      Vimos que você está tentando pesquisar as informações sobre o agente de CAS {{ cas }}. <br/>
      Para pesquisar este agente clique sobre o CAS abaixo.
      <b-row class='mt-3'>
        <b-col>
          <base-button
            danger
            block
            @click="searchWithoutExtractingCASNumber()" >
            Cancelar
          </base-button>
        </b-col>

        <b-col>
          <base-button
            success
            @click="extractStringCas" block>
            {{ cas }}
          </base-button>
        </b-col>
      </b-row>
    </b-modal>

    <table class="text-center table b-table table-bordered custom-table mt-1" >
      <thead class='sticky'>
        <tr class='sticky' >
          <th colspan='9' class='container-tab-buttons' >
            <tab-buttons />
          </th>
        </tr>

        <tr style='' class='search-agent table-bordered sticky'>
          <th colspan='1'>
            <b-form-checkbox class='pb-2 text-white' :checked="selectedAll" @change="selectAll()">
              Todos
            </b-form-checkbox>
          </th>
          <th colspan='3' >
            <div class='d-flex justify-content-between align-items-center' >
              <vue-bootstrap-typeahead
                class="mb-2 mb-sm-2 legal-search pr-2 w-100"
                v-model="agent"
                :maxMatches="maxMatchesSelect"
                :serializer="(item) => item.searchMatched"
                :data="agents"
                sort-variant="length"
                @hit="selectByAgente($event)"
                @input="clear"
                placeholder="Pesquise um CAS: Agente para iniciar"
                ref="agenBaseAutocomplete"
              />
              <section class='ml-1 d-flex align-items-start'>
                <b-spinner class='loading mb-3' v-if="loading" variant="primary" label="Spinning"></b-spinner>

                <section v-else class='d-flex'>
                  <b-tooltip custom-class="custom-tooltip" target="popover-target-1" placement="top" triggers="hover">
                    Para pesquisar agentes químicos digite o NOME/SINÔNIMO do
                    agente químico ou então o seu CAS. Não realize a pesquisa
                    digitando o CAS e o nome do agente juntos. Caso a sua pesquisa
                    seja feita através do número CAS, por favor, digite o CAS no
                    seguinte padrão: 2 ou mais dígitos - 2 dígitos - 1 dígito.
                    Exemplo: 50-00-0, 108-88-3, 64742-47-8. Os números CAS não
                    possuem espaços entre os dígitos e os traços.
                  </b-tooltip>

                  <b-icon
                    class='ml-1 mb-2'
                    font-scale="2.2"
                    icon="question-circle-fill"
                    id="popover-target-1"
                    variant="info"
                  >
                  </b-icon>

                  <section
                    style='margin-top: -8px'
                    class='ml-3'
                  >
                    <strong
                      v-if='summaryMeta && summaryMeta.total'
                      class='text-white'
                    >
                      Página <br/>
                      {{summaryMeta.skip}} de {{summaryMeta.totalPage}}
                    </strong>
                  </section>
                </section>
              </section>
            </div>
          </th>

          <th colspan="6">
            <div class='d-flex'>
              <div class='title-legend-summary'><span>Legendas </span></div>
              <div class="legends-summary">
                <span>
                  <img width='18' height='14' src='../../assets/images/consta.svg'  alt='Conta'/> consta
                </span>
                <span>
                  <img width='14' height='14' src='../../assets/images/naoConsta.svg'  alt='Não consta'/> não consta
                </span>
                <span>
                  <img width='16' height='16' src='../../assets/images/classificacao.svg'  alt='Em classificação'/> em classificação
                </span>
              </div>
            </div>
          </th>
        </tr>

        <tr class='sticky table-bordered'>
          <th class='width-8-percent'>Selecionar</th>
          <th colspan='2' class='width-44-percent'>
            CAS: Agente
            <b-dropdown
              variant="outline-dange"
              menu-class='w-100 options'
              class='btn-options'
              no-flip
              no-caret
            >
              <template #button-content>
                <b-icon
                  icon='arrow-down-short'
                  font-scale="1.5"
                  variant='info'>
                </b-icon>
              </template>
              <span class='ml-4'>Organizar os agentes por:</span>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item
                @click="orderBy('nomeAgente')"
              >
                <section class='d-flex justify-content-between align-items-center'>
                  <span>Ordem alfabética por nome do agente</span>
                  <b-icon
                    v-if="summaryMeta && summaryMeta.orderBy && summaryMeta.orderBy === 'nomeAgente'"
                    icon='check'
                    variant='success'
                  >
                  </b-icon>
                </section>
              </b-dropdown-item>

              <b-dropdown-item
                @click="orderBy('numeroCas')"
              >
                <section class='d-flex justify-content-between align-items-center'>
                  <span>Ordem numérica por cas</span>
                  <b-icon
                    v-if="summaryMeta && summaryMeta.orderBy && summaryMeta.orderBy === 'numeroCas'"
                    icon='check'
                    variant='success'
                  >
                  </b-icon>
                </section>
              </b-dropdown-item>

              <b-dropdown-item
                @click="orderBy('nomeProduto')"
              >
                <section class='d-flex justify-content-between align-items-center'>
                  <span>Ordem alfabética por nome do produto (ordenando os produtos)</span>
                  <b-icon
                    v-if="summaryMeta && summaryMeta.orderBy && summaryMeta.orderBy === 'nomeProduto'"
                    icon='check'
                    variant='success'
                  >
                  </b-icon>
                </section>
              </b-dropdown-item>
            </b-dropdown>
          </th>
          <th class='width-8-percent'>ACGIH</th>
          <th class='width-8-percent'>NR 15</th>
          <th class='width-8-percent'>LINACH</th>
          <th class='width-8-percent'>ESOCIAL - Tabela 24</th>
          <th class='width-8-percent'>DECRETO 3048/99 - Anexo IV</th>
          <th v-if='importedCompany && importedCompany._id' class='width-8-percent'>Ações</th>
        </tr>
      </thead>

      <tbody class='table-bordered'>
      <template v-for="product in legalConsultationAgentsGroupedByProduct">
        <template v-for="(item, indexLegalSearche) in product.legalSearches" >
          <tr :key="`${indexLegalSearche}_item_${item.legalSearchId}`">
            <td
              :style="`${itemsToRemove.find((x) => x === item.legalSearchId)
              ? `background: rgba(4, 168, 243, 0.1); word-wrap: break-word;`
              : `word-wrap: break-word`}`"
            >
              <b-form-checkbox
                @change="selectAgentRemove(item)"
                :checked="checked(item.legalSearchId)">
              </b-form-checkbox>
            </td>

            <template v-if="product.product._id !==  '-' ">
              <td
                v-if='item.index < 1'
                :rowspan="product.legalSearches.length"
              >
                <section v-if="product.product._id !==  '-'">
                  <section v-if="product.product._id === idDefault">
                    <a
                      :style="selectedMoreThanOneProduct ? 'pointer-events: none' : ''"
                      v-if='importedCompany && importedCompany._id'
                      class='word-break add-agent-prouct'
                      @click="addAgentsProduct()"
                    >
                      Adicionar agente(s) <br/> ao produto
                    </a>
                  </section>

                  <section v-else>
                    <p class='word-break'>
                      <span>
                        Produto: {{ product.product.name}}
                      </span>
                      <br/>

                      <span v-if='product.product.role && product.product.role._id'>
                        Cargo: {{product.product.role.name}}
                      </span>
                    </p>

                    <section class='mb-2' v-if="synergisticRiskProducts && synergisticRiskProducts.length > 0 && !!synergisticRiskProducts.find((x) => x.product === product.product._id)">
                      <b-tooltip
                        :target="`${item.cas}_${item.legalSearchId}_${product.product._id}_synergisticRiskProducts`"
                        custom-class="custom-tooltip"
                        placement="auto"
                        triggers="hover"
                      >
                        <strong>Este produto contém agentes químicos que afetam de forma acumulativa o mesmo órgão ou sistema alvo:</strong> <br/>
                        <ul class='phrases mt-2'>
                          <li
                            v-for='phrase in synergisticRiskProducts.find((x) => x.product === product.product._id).phrases'
                            :key='phrase'
                          >
                            {{phrase}}
                          </li>
                        </ul>
                      </b-tooltip>

                      <button
                        class='btn-synergistic-risk'
                        :id="`${item.cas}_${item.legalSearchId}_${product.product._id}_synergisticRiskProducts`"
                      >
                        <svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.034 8.5645L10.6878 1.2175C10.4057 0.93712 10.0242 0.779739 9.62651 0.779739C9.22882 0.779739 8.8473 0.93712 8.56526 1.2175L1.21901 8.5645C0.93863 8.84654 0.78125 9.22806 0.78125 9.62575C0.78125 10.0234 0.93863 10.405 1.21901 10.687L8.56601 18.0333C9.14952 18.6168 10.1035 18.6168 10.6885 18.0333L18.0355 10.6863C18.3151 10.4041 18.4719 10.023 18.4719 9.62575C18.4719 9.22855 18.3151 8.84741 18.0355 8.56525L18.034 8.5645ZM9.02876 4.735C9.19376 4.55725 9.40376 4.468 9.66176 4.468C9.91976 4.468 10.1298 4.5565 10.2948 4.73275C10.4598 4.909 10.5423 5.13025 10.5423 5.3965C10.5423 5.626 10.4673 6.39625 10.315 7.708C10.165 9.0205 10.03 10.288 9.91751 11.512H9.37976C9.27851 10.2873 9.15476 9.01975 9.00476 7.708C8.85626 6.3955 8.78126 5.626 8.78126 5.3965C8.78126 5.134 8.86376 4.915 9.02876 4.7365V4.735ZM10.3263 14.35C10.1403 14.53 9.91901 14.62 9.66176 14.62C9.40451 14.62 9.18326 14.53 8.99801 14.35C8.81276 14.1685 8.72051 13.9495 8.72051 13.6923C8.72051 13.435 8.81276 13.2138 8.99801 13.0285C9.18401 12.8425 9.40526 12.7495 9.66251 12.7495C9.91976 12.7495 10.141 12.8425 10.3263 13.0285C10.5138 13.2138 10.606 13.435 10.606 13.6923C10.606 13.9495 10.513 14.1685 10.327 14.3493L10.3263 14.35Z" fill="#022245"/>
                        </svg>
                      </button>
                    </section>
                    <a
                      :style="selectedMoreThanOneProduct || disableProduct ? 'pointer-events: none' : ''"
                      v-if='importedCompany && importedCompany._id && showAddAgents(product)'
                      class='word-break add-agent-prouct'
                      @click="callGlobalCustomEvent('showModalAddProduct')"
                    >
                      Adicionar agente(s) <br/> ao produto: {{product.product.name}}
                    </a>

                    <section v-if='disableProduct' class='d-flex align-items-center justify-content-center mt-2'>
                      <b-spinner
                        class='loading-button'
                        variant='info'
                        label="Spinning">
                      </b-spinner>
                      <strong class='ml-2 ' style='color: #04A8F3'>Adicionando...</strong>
                    </section>
                  </section>
                </section>
              </td>
            </template>

            <template v-else>
              <td>
                <section class='px-2'>
                  <a
                    v-if='importedCompany && importedCompany._id'
                    class='word-break add-agent-prouct'
                    @click="addAgentProduct(item.legalSearchId)"
                  >
                    Adicionar agente <br/> ao produto
                  </a>

                  <p
                    v-else
                    class='word-break '
                  >
                    -
                  </p>
                </section>
              </td>
            </template>

            <td
              :style="`${itemsToRemove.find((x) => x === item.legalSearchId)
              ? `background: rgba(4, 168, 243, 0.1); word-wrap: break-word;`
              : `word-wrap: break-word`}`"
            >

              <b-tooltip
                :target="`${item.legalSearchId}-${item.cas}-${item.index}-searchId`"
                custom-class="custom-tooltip"
                placement="auto"
                triggers="hover"
              >
                <section
                  class='p-2'
                  v-if='(item.attachment11 && item.attachment11.group) || item.nameAttachment11'
                >
                <span
                  class='text-white'
                  style='font-size: 15px'
                >
                  Este agente consta na NR 15 com o seguinte nome:
                  {{ item.attachment11 && item.attachment11.group
                  ? item.attachment11.group
                  : item.nameAttachment11 ? item.nameAttachment11: '-'
                  }}
                </span><br/><br/>
                </section>

                <section
                  class='p-2'
                  v-if='item.acgih && item.agent'
                >
                  <span
                    class='text-white'
                    style='font-size: 15px'
                  >
                    Este agente está listado no livreto da ACGIH conforme a versão vigente no software HO Fácil Web através do nome:
                    {{ item.agent ? item.agent : '-' }}
                  </span>
                  <br/>
                  <br/>
                </section>

                <a
                  :href="`https://pubchem.ncbi.nlm.nih.gov/#query=${item.cas}`"
                  target='_blank'
                  class='link-pubchem-cas p-2'
                >
                <span>
                  Clique <strong>aqui</strong> para ver a imagem da estrutura molecular do agente químico {{item.cas}}
                </span>
                </a>
              </b-tooltip>

              <a
                href="#" class='hover_links word-break'
                :id="`${item.legalSearchId}-${item.cas}-${item.index}-searchId`"
              >
                <span class='word-break'>{{ model.getsearchId('searchId', item) }}</span>

                <b-icon
                  class='ml-1'
                  font-scale="1.2"
                  icon="info-circle-fill"
                  variant="info"
                >
                </b-icon>
              </a>
            </td>

            <td
              :style="`${itemsToRemove.find((x) => x === item.legalSearchId)
                ? `background: rgba(4, 168, 243, 0.1); word-wrap: break-word; ${styleWidth('acgih')}`
                : `word-wrap: break-word`}; ${styleWidth('acgih')}`"
            >
              <img
                width='18'
                height='16'
                v-bind:src="require(`../../assets/images/${model.getExist('acgih', item)}.svg`)"
                alt=''
              />
            </td>

            <td
              :style="`${itemsToRemove.find((x) => x === item.legalSearchId)
                ? `background: rgba(4, 168, 243, 0.1); word-wrap: break-word; ${styleWidth('nr15')}`
                : `word-wrap: break-word`}; ${styleWidth('nr15')}`"
            >
              <img
                width='18'
                height='16'
                v-bind:src="require(`../../assets/images/${model.getExist('nr15', item)}.svg`)"
                alt=''
              />
            </td>

            <td
              :style="`${itemsToRemove.find((x) => x === item.legalSearchId)
                ? `background: rgba(4, 168, 243, 0.1); word-wrap: break-word; ${styleWidth('linach')}`
                : `word-wrap: break-word`}; ${styleWidth('linach')}`"
            >
              <img
                width='18'
                height='16'
                v-bind:src="require(`../../assets/images/${model.getExist('linach', item)}.svg`)"
                alt=''
              />
            </td>

            <td
              :style="`${itemsToRemove.find((x) => x === item.legalSearchId)
                ? `background: rgba(4, 168, 243, 0.1); word-wrap: break-word; ${styleWidth('esocial')}`
                : `word-wrap: break-word`}; ${styleWidth('esocial')}`"
            >
              <img
                width='18'
                height='16'
                v-bind:src="require(`../../assets/images/${model.getExist('esocial', item)}.svg`)"
                alt=''
              />
            </td>

            <td
              :style="`${itemsToRemove.find((x) => x === item.legalSearchId)
                ? `background: rgba(4, 168, 243, 0.1); word-wrap: break-word; ${styleWidth('decree3048')}`
                : `word-wrap: break-word`}; ${styleWidth('decree3048')}`"
            >
              <img
                width='18'
                height='16'
                v-bind:src="require(`../../assets/images/${model.getExist('decree3048', item)}.svg`)"
                alt=''
              />
            </td>

            <template v-if='importedCompany && importedCompany._id'>
              <template v-if="product.product._id !==  '-' ">
                <td
                  v-if='item.index < 1'
                  :rowspan="product.legalSearches.length"
                  :style="`${itemsToRemove.find((x) => x === item.legalSearchId)
                    ? `background: rgba(4, 168, 243, 0.1); word-wrap: break-word; ${styleWidth('actions')}`
                    : `word-wrap: break-word`}; ${styleWidth('actions')}`"
                >
                  <img
                    @click="callGlobalCustomEvent('showModalAddProduct', product.product._id)"
                    v-b-tooltip.lefttop.hover
                    title='Clique aqui para editar o produto.'
                    style='cursor: pointer'
                    class="mr-1 button-remove"
                    width='28'
                    height='28'
                    src='../../assets/images/bxs_edit.svg'
                    alt=''
                  />
                </td>
              </template>
              <template v-else>
                <td
                  :style="`${itemsToRemove.find((x) => x === item.legalSearchId)
                    ? `background: rgba(4, 168, 243, 0.1); word-wrap: break-word; ${styleWidth('actions')}`
                    : `word-wrap: break-word`}; ${styleWidth('actions')}`"
                >
                  <img
                    style='cursor: pointer'
                    class="mr-1 button-remove"
                    @click="$emit('remove-confirmation', item.legalSearchId)"
                    width='24'
                    height='24'
                    src='../../assets/images/lixeira.svg'
                    alt=''
                  />

                </td>
              </template>
            </template>
          </tr>
        </template>
      </template>
      </tbody>
    </table>

    <section v-if='!summary || summary.length <= 0'>
      <h6 class='text-center my-4' v-if='!lookingForAgents'>
        <span v-if='appliedFilters'>
          Não foi encontrado agentes baseado nos filtros selecionados.
        </span>

        <span v-else>
           Você ainda não possui agentes cadastrados, pesquise pelo número CAS ou nome do agente para adicionar agente na sua lista.
        </span>
      </h6>
    </section>
  </div>
</template>

<script>
import LegalSearchSummary from '@/models/LegalSearchSummary'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import _ from 'underscore'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { agentCASNumberIsValid, extractCASNumber, extractString } from '../../utils/validateCASAgent'
import api from '../../utils/api'
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/validators'
import TabButtons from '@/components/LegalSearch/components/TabButtons.vue'
import eventBus from '@/utils/eventBus'
export default {
  async mounted() {
    this.$refs.agenBaseAutocomplete.$el.querySelector('input').addEventListener('keyup', (e) => {
      const typedValue = this.$refs.agenBaseAutocomplete.inputValue.replace(/^0+/,'')
      const searchedByCas = agentCASNumberIsValid(typedValue);
      if(searchedByCas) {
        this.$refs.agenBaseAutocomplete.inputValue = this.$refs.agenBaseAutocomplete.inputValue.replace(/^0+/,'')
        this.$refs.agenBaseAutocomplete.inputValue = this.$refs.agenBaseAutocomplete.inputValue.replace(' ', '')
      }
      this.agent = this.$refs.agenBaseAutocomplete.inputValue
    })
    this.$parent.$on('addChemicalAgentLegalConsultation', this.add);
    await this.handlGetEventActive()
  },
  name: 'SummaryTable',
  async beforeDestroy() {
    try {
      this.summary.forEach((x) => {
        if (!x.registeredProduct) {
          delete x.product
        }
      })
      await this.groupAgents(this.summary)
      await this.setSlectedAgents([])
    } catch (e) {

    }
  },
  async created () {},
  components: {
    VueBootstrapTypeahead,
    TabButtons
  },
  props: {
    selectedAll: { type: Boolean },
    itemsToRemove: { type: Array },
    limitAgentsAddedInAgentSelection: { type: Number, default: 1000 },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      model: LegalSearchSummary,
      cas: '',
      loading: false,
      hidden: true,
      disabled: true,
      removeAll: '',
      removeItems: [],
      maxMatchesSelect: 100,
      agent: '',
      summaryFilter: [],
      summaryFilterMeta: {
        search: '',
        skip: 1
      },
      lookingForAgentsFilter: false,
      agents: [],
      removeItem: '',
      hitted: false,
      inventoryMessage: '',
      inventory: [],
      message: '',
      options: [],
      legalSearchesId: [],
      alertvariant: 'success',
      dailySearches: {},
      modal: {
        current: "ModalRequestAgent",
        open: false
      },
      casSearch: '',
      idDefault: 123456789,
    }
  },
  methods: {
    ...mapActions('userPlans', {
      handleGetAddedAgentsLimitation: 'handleGetAddedAgentsLimitation',
    }),
    ...mapActions('objectivesAnalysis', {
      handleUpdateChangedAgentsLegalConsultation: 'handleUpdateChangedAgentsLegalConsultation',
    }),
    ...mapActions('legalSearch', {
      handleResetSummaryMeta: 'handleResetSummaryMeta',
      setSkip: 'setSkip',
      summaryClear: 'summaryClear',
      handleSetSummaryMetaOrderBy: 'handleSetSummaryMetaOrderBy',
      setSlectedAgents: 'setSlectedAgents',
    }),
    ...mapActions('searchAgents', {
      handleCreateSearchAgents: 'handleCreateSearchAgents',
    }),
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive',
    }),
    ...mapActions('companyLegalConsultation', {
      handleAddAgentCompanyLegalConsultation: 'handleAddAgentCompanyLegalConsultation',
    }),
    ...mapActions('legalSearch', {
      setLegalConsultationAgentsGroupedByProduct: 'setLegalConsultationAgentsGroupedByProduct',
    }),
    ...mapActions('freeModeAgents', {
      handleAddAgentsFreeModeAgents: 'handleAddAgentsFreeModeAgents',
    }),

    async orderBy(orderBy) {
      await this.setSkip(1)
      await this.handleSetSummaryMetaOrderBy(orderBy)
      await this.load()
    },

    rowspan(legalConsultationAgentsGroupedByProduct, legalSearch){
      if(legalConsultationAgentsGroupedByProduct.product._id === `-`){
        return 1
      } else {
        return legalConsultationAgentsGroupedByProduct.legalSearches.length
      }
    },

    callGlobalCustomEvent(event, productId) {
      eventBus.$emit(event, productId)
    },

    async addAgentProduct(legalSearchId) {
      this.callGlobalCustomEvent('clearItems')
      const legalSearchesGroupedBySelectedAux = []
      this.legalConsultationAgentsGroupedByProduct.forEach((x) => {
        x.legalSearches.forEach((y) => {
          if (!y.registeredProduct) {
            delete y.product
          }
          legalSearchesGroupedBySelectedAux.push(y)
        })
      })
      await this.groupAgents(legalSearchesGroupedBySelectedAux)
      this.$emit('select', legalSearchId)
      this.callGlobalCustomEvent('showModalAddProduct')
    },

    addAgentsProduct(){
      this.callGlobalCustomEvent('showModalAddProduct')
    },

    async filter() {
      await this.setSkip(1)
      await this.summaryClear()
      this.$refs.filter.hide();
      await this.load();
    },

    async resetSummaryMeta() {
      await this.summaryClear()
      await this.setSkip(1)
      this.$refs.filter.hide();
      await this.handleResetSummaryMeta();
      await this.load();
    },

    showModalFilter(){
      this.$refs.filter.show()
    },

    styleWidth(field){
      switch (field.key) {
        case 'searchId':
          return 'max-width : 200px'
        default:
          return 'width : 120px'
      }
    },

    async add() {
      try {
        this.legalSearchesId = []
        this.disabled = true
        const userReachedLimitAddedAgents = this.userReachedLimitAddedAgents();
        if(userReachedLimitAddedAgents){
          this.selectedAgent = undefined
          this.$refs.agenBaseAutocomplete.inputValue = ''
          this.$emit('daily-searches', this.limitationOfAgentsAdded)
          return
        }

        if(this.summaryMeta.total >= this.limitAgentsAddedInAgentSelection){
          this.selectedAgent = undefined
          this.$refs.agenBaseAutocomplete.inputValue = ''
          const title = 'Atingido o limite de agentes adicionados';
          const message = `Para adicionar mais agentes é necessário apagar agentes para adicionar outros, o limte de agentes adicionados é ${this.limitAgentsAddedInAgentSelection}!`
          this.$emit('addedAgentLimits', title, message)
          return
        }
        let orderingDate = null
        let lastPaginationAgent = null
        const addAgentsLegalConsultationTopList = this.user.preferences && this.user.preferences.addAgentsLegalConsultationTopList ? this.user.preferences.addAgentsLegalConsultationTopList : false
        const pageQuantity = Math.ceil(this.summaryMeta.total / this.agentsPerPage)

        if(this.summary.length > 0) {
          if(this.summaryMeta.skip < pageQuantity){
            lastPaginationAgent = this.summary.at(-1).orderingDate
            if (addAgentsLegalConsultationTopList && this.summary.length > 0) {
              lastPaginationAgent = this.summary.at(0).orderingDate
            }
            const endDate = moment(lastPaginationAgent);
            endDate.add(-1, 'milliseconds');
            orderingDate = endDate
          } else {
            orderingDate = null
            if (addAgentsLegalConsultationTopList && this.summary.length > 0) {
              lastPaginationAgent = this.summary.at(0).orderingDate
              const endDate = moment(lastPaginationAgent);
              endDate.add(-1, 'milliseconds');
              orderingDate = endDate
            }
          }
        }
        const { status, body } = (
          await this.$store.dispatch('legalSearch/add', {
            user: this.user.id,
            agent: this.selectedAgent._id,
            name: this.selectedAgent.searchMatched,
            orderingDate,
            acgih_data: {},
          })
        ).data

        if (status === 204) {
          this.$emit('daily-searches', body)
          return
        }

        this.$refs.agenBaseAutocomplete.inputValue = ''
        await this.load()
        await this.$store.dispatch('legalSearch/finishInsertionAgents', {
          user: this.user.id,
          agent: this.selectedAgent._id,
        })

        const sessionId = localStorage.getItem("sessionId");
        await this.$api.delete(`users/cache/${sessionId}`);
        const user = (await this.$api.get(`users/${sessionId}`)).data;
        await this.$store.dispatch('user/updateUser', user);
        await this.handleGetAddedAgentsLimitation(this.user)
        const { _id } = body
        this.legalSearchesId.push(_id)
        if (this.importedCompany && this.importedCompany._id) {
          const bodyAddAgentCompany = {
            user: this.user.id,
            companyId: this.importedCompany._id,
            legalSearchesId: this.legalSearchesId
          }
          await this.handleAddAgentCompanyLegalConsultation(bodyAddAgentCompany)

          this.$bvToast.toast(`As informações da empresa foram atualizadas.`, {
            title: 'Atenção',
            variant: 'success',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 6000,
          });
        } else {
          await this.handleAddAgentsFreeModeAgents(
            {
              user: this.user.id,
              agents: this.legalSearchesId
            }
          )
        }
        this.selectedAgent = undefined
      } catch (err) {
        console.log(err)
      }
    },

    checked(id) {
      return this.itemsToRemove.indexOf(id) >= 0
    },

    clear() {
      if (this.$refs.agenBaseAutocomplete.inputValue === '') {
        this.selectedAgent = undefined
        this.disabled = true
      }
    },

    async load() {
      this.loading = false
      await this.$store.dispatch('legalSearch/load', this.user.id)
    },

    async remove() {
      await this.$store.dispatch('legalSearch/remove', this.itemsToRemove)
      this.itemsToRemove = []
      await this.load()
      this.$refs.rmconfirmation.hide()
    },

    removeConfirmation(i) {
      this.itemsToRemove.push(i)
      this.$refs.rmconfirmation.show()
    },

    select(id) {
      let index = this.itemsToRemove.indexOf(id)
      if (index >= 0) {
        this.itemsToRemove.splice(index, 1)
      } else {
        this.itemsToRemove.push(id)
      }
    },

    async selectByAgente($event) {
      this.loading = false
      let agent = $event
      this.hitted = true
      this.options = this.agents.filter((item) => {
        if (agent.searchMatched === item.searchMatched) return item
      })
      this.selectedAgent = $event
      this.agent = ''
      this.disabled = false
      await this.add()
      await this.handleUpdateChangedAgentsLegalConsultation(true)
    },

    extractStringCas(){
      this.agent = this.cas
      this.$refs.agenBaseAutocomplete.inputValue = extractCASNumber(this.agent)
      this.$refs['alert-cas'].hide()
      this.searchChemicalAgents(extractCASNumber(this.cas))
    },

    searchWithoutExtractingCASNumber(){
      this.$refs['alert-cas'].hide()
      this.searchChemicalAgents(this.agent)
    },

    async searchChemicalAgents(newQuery) {
      try {
        this.loading = true
        this.casSearch = newQuery
        const response = (await this.$api.get(`agents/search?agent=${newQuery}`)).data
        this.agents = response.chemicalAgents
        if (response.chemicalAgents.length <= 0) {
          this.modal.current = response.searchedByCas ? 'ModalRequestAgent' : 'ModalAgentNotFound'
          this.modal.open = true
          this.$emit('open-modal', { modal: this.modal, agent: this.agent, casSearch: this.casSearch })
        }
        await this.handleCreateSearchAgents({user: this.user.id, researched: newQuery})
        this.loading = false
      } catch (e) {

      } finally {
        this.loading = false

      }
    },

    userReachedLimitAddedAgents(){
      return this.limitationOfAgentsAdded && this.limitationOfAgentsAdded.limitation <= this.limitationOfAgentsAdded.added ;
    },

    async selectAll() {
      await this.$emit('select-all')

      const agentes = this.summary.filter((x) => this.itemsToRemove.includes(x.legalSearchId))
      const agentesVinculadoProduto = agentes.filter((x) => x.product && x.product._id)
      const agentesVinculadoProdutoAgrupadoPorProduto = agentesVinculadoProduto.reduce((group, agent) => {
        const product = agent.product._id
        group[product] = group[product] ? group[product] : []
        group[product].push(agent)
        return group
      }, {})

      if (Object.keys(agentesVinculadoProdutoAgrupadoPorProduto).length === 1) {
        const legalSearchesGroupedBySelectedAux = []
        this.legalConsultationAgentsGroupedByProduct.forEach((x) => {
          legalSearchesGroupedBySelectedAux.push(...x.legalSearches)
        })

        let productAgent = null
        const legalSearchesProducts = legalSearchesGroupedBySelectedAux.filter((x) => this.itemsToRemove.includes(x.legalSearchId) && x.product && x.product._id && x.registeredProduct)
        if (legalSearchesProducts.length > 0) {
          const [legalSearch] = legalSearchesProducts
          const { product } = legalSearch
          if (product) {
            productAgent = product
          }
        }

        legalSearchesGroupedBySelectedAux.forEach((x) => {
          if(!x.product || !x.registeredProduct){
            x.product = productAgent
          }
        })
        await this.groupAgents(legalSearchesGroupedBySelectedAux)
      } else {
        this.summary.forEach((x) => {
          if (!x.registeredProduct) {
            delete x.product
          }
        })
        await this.groupAgents(this.summary)
      }
    },

    async selectAgentRemove(legalSearch) {
      const { legalSearchId, product, registeredProduct } = legalSearch;
      await this.$emit('select', legalSearchId)

      const legalSearchesGroupedBySelectedAux = []

      this.legalConsultationAgentsGroupedByProduct.forEach((x) => {
        legalSearchesGroupedBySelectedAux.push(...x.legalSearches)
      })

      const legalSearchesProducts = legalSearchesGroupedBySelectedAux.filter((x) => this.itemsToRemove.includes(x.legalSearchId) && x.product && x.product._id && x.registeredProduct)
      const legalSearchesProductsGroupBy = legalSearchesProducts.reduce((group, agent) => {
        const product = agent.product && agent.product._id ? agent.product._id : `-`;
        group[product] = group[product] ? group[product] : [];
        group[product].push(agent);
        return group;
      }, {})

      if (Object.keys(legalSearchesProductsGroupBy).length >= 2) {
        this.$bvToast.toast(`Não é possível vincular um agente químico em dois ou mais produtos de uma vez.`, {
          title: 'Aviso',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        await this.$emit('select', legalSearchId)
        return
      }
      let productAgent = null

      if (this.checked(legalSearchId)) {
        if (product && product._id && product._id && registeredProduct) {
          const legalSearchesProductsGroupBy = legalSearchesGroupedBySelectedAux.filter((x) => this.itemsToRemove.includes(x.legalSearchId) && x.product && x.product._id && !x.registeredProduct)
          if (legalSearchesProducts.length > 0) {
            const [legalSearch] = legalSearchesProducts
            const { product } = legalSearch
            if (product) {
              productAgent = product
            }
          }
          legalSearchesGroupedBySelectedAux.forEach((x) => {
             const legalSearchesGroupedBy = legalSearchesProductsGroupBy.find((y) => y.legalSearchId === x.legalSearchId)
              if(legalSearchesGroupedBy){
                x.product = productAgent
              }
          })
        } else {
          if (legalSearchesProducts.length > 0) {
            const [legalSearch] = legalSearchesProducts
            const { product } = legalSearch
            if (product) {
              productAgent = product
              productAgent.groupBy = true
            }
          }
          legalSearchesGroupedBySelectedAux.forEach((x) => {
            if (x.legalSearchId === legalSearchId) {
              x.product = productAgent ? productAgent : {
                _id: this.idDefault,
                name: undefined,
                description: undefined,
              }
            }
          })
        }
      } else {
        legalSearchesGroupedBySelectedAux.forEach((x) => {
          if(!x.registeredProduct && x.legalSearchId === legalSearchId) {
            delete x.product
          } else if(x.registeredProduct && x.legalSearchId === legalSearchId) {
            const legalSearchesProductsGroupBy = legalSearchesGroupedBySelectedAux.filter((x) => this.itemsToRemove.includes(x.legalSearchId) && x.product && x.product._id && x.registeredProduct)
            if (legalSearchesProductsGroupBy.length === 0 && this.itemsToRemove.length > 0) {
              const legalSearchesProductsGroupByNotRegisteredProduct = legalSearchesGroupedBySelectedAux.filter((x) => this.itemsToRemove.includes(x.legalSearchId) && x.product && x.product._id && !x.registeredProduct)
              legalSearchesGroupedBySelectedAux.forEach((x) => {
                const legalSearchesGroupedBy = legalSearchesProductsGroupByNotRegisteredProduct.find((y) => y.legalSearchId === x.legalSearchId)
                if (legalSearchesGroupedBy) {
                  x.product = { _id: this.idDefault, name: undefined, description: undefined}
                }
              })
            }
          }
        })
      }

      await this.groupAgents(legalSearchesGroupedBySelectedAux)
    },

    showAddAgents(product){
      const { legalSearches } = product
      const agents = legalSearches && legalSearches.length > 0 ? legalSearches.filter((f) => f.registeredProduct).map((x) => x.legalSearchId) : []
      const selecionouAgentesSemVinculoProuto = this.itemsToRemove.some((element) => !(!!(agents.find((x) => x === element))));
      const naoSelecionouAgentesSemVinculoProuto = this.itemsToRemove.some((element) => (!!(agents.find((x) => x === element))));
      return selecionouAgentesSemVinculoProuto && naoSelecionouAgentesSemVinculoProuto;
    },

    async groupAgents(agents) {
      try {
        const legalConsultationAgentsGroupedByProduct = []

        const legalSearchGroupByProduct = agents.reduce((group, agent) => {
          const product = agent.product && agent.product._id ? agent.product._id : `-`;
          group[product] = group[product] ? group[product] : [];
          group[product].push(agent);
          return group;
        }, {})

        Object.keys(legalSearchGroupByProduct).forEach((legalSearchGroupBy) => {
          legalConsultationAgentsGroupedByProduct.push(
            {
              product: legalSearchGroupBy !== `-`
                ? legalSearchGroupByProduct[legalSearchGroupBy].find((agent) => agent.product._id.toString() === legalSearchGroupBy).product
                : { _id: `-`, name: `-`, description: `-` },
                legalSearches: legalSearchGroupByProduct[legalSearchGroupBy].map((x, index) => {
                const legalSearch = x
                legalSearch.index = index
                return legalSearch
              })
            }
          )
        })
        await this.setLegalConsultationAgentsGroupedByProduct(legalConsultationAgentsGroupedByProduct)
      } catch (e) {

      }
    },



  },
  computed: {
    ...mapGetters('legalSearch', {
      summary: 'summary',
    }),
    ...mapGetters('userPlans', {
      limitationOfAgentsAdded: 'limitationOfAgentsAdded'
    }),
    selectedAgent: {
      get: function () {
        return this.$store.getters['legalSearch/selectedAgent']
      },
      set: function (agent) {
        this.$store.dispatch('legalSearch/select', agent)
      },
    },
    user() {
      return this.$store.getters['user/current']
    },
    agentsPerPage() {
      return this.$store.getters['legalSearch/agentsPerPage']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    summary() {
      return this.$store.getters['legalSearch/summary']
    },
    legalConsultationAgentsGroupedByProduct() {
      return this.$store.getters['legalSearch/legalConsultationAgentsGroupedByProduct']
    },
    lookingForAgents() {
      return this.$store.getters['legalSearch/lookingForAgents']
    },
    appliedFilters() {
      return this.$store.getters['legalSearch/appliedFilters']
    },
    ...mapGetters('companyLegalConsultation', {
      importedCompany: 'importedCompany'
    }),
    ...mapGetters('productLegalConsultation', {
      disableProduct: 'disableProduct'
    }),
    selectedMoreThanOneProduct(){
      const agentes = this.summary.filter((x) => this.itemsToRemove.includes(x.legalSearchId))
      const agentesVinculadoProduto = agentes.filter((x) => x.product && x.product._id)
      const agentesVinculadoProdutoAgrupadoPorProduto = agentesVinculadoProduto.reduce((group, agent) => {
        const product = agent.product._id
        group[product] = group[product] ? group[product] : []
        group[product].push(agent)
        return group
      }, {})
      return Object.keys(agentesVinculadoProdutoAgrupadoPorProduto).length >= 2
    },
    synergisticRiskProducts() {
      return this.$store.getters['legalSearch/synergisticRiskProducts']
    },
    synergisticRiskRoles() {
      return this.$store.getters['legalSearch/synergisticRiskRoles']
    },
  },
  watch: {
    agent: _.debounce(async function (newQuery) {
      if (!newQuery || newQuery.length < 3) return
      const text = extractString(newQuery);
      const cas = extractCASNumber(newQuery);

      if(agentCASNumberIsValid(cas) && text && text.length > 0){
        this.cas = cas
        this.$refs['alert-cas'].show()
        return
      }
      await this.searchChemicalAgents(newQuery)
    }, 1500),
  },
}
</script>
<style lang='scss'>
.hover_links{
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #04A8F3;
}
.width-8-percent{
  width: 8%!important;
}
.width-44-percent{
  width: 44%!important;
}
.options {
  border-radius: 6px !important;
  min-width: 590px!important;
}
.btn-options {
  border-radius: 5px !important;
  background: transparent !important;
  border: 1px solid transparent!important;
  width: 5px!important;
  text-align: center;
}
.btn-options:active {
  border: 1px solid transparent!important;
}

.headerMinWidth{
  min-width: 200px
}
.sticky {
  position: sticky;
  top: 10px;
  z-index: 10;
}
.search-agent {
  background-color: #022245 !important;
  height: 90px;
  z-index: 11;
}
tr:nth-child(3) th {
  background: #94D5F3;
}
.container-tab-buttons{
  padding: 0 0 3px 0!important;
}
.loading{
  width: 2.5rem;
  height: 2.5rem;
}
button.button-inventory:not(:disabled) {
  background-color: green;
}
.legal-search > .form-control {
  background-image: url('/img/loading.gif');
}
.loadingLookingForAgents{
  width: 2rem;
  height: 2rem;
}
.text-loading{
  font-size: 18px;
}

.text-opcoes-relavao-legal{
  font-size: 16px;
}
.add-agent-prouct{
  color:  #04A8F3;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
  min-width: 85px;
}
.btn-synergistic-risk{
  width: 32px!important;
  height: 32px!important;
  background: #CBD63E!important;
  border: 1px solid #022245;
  border-radius: 2px;
}
.phrases{
  list-style: inside;
  margin-left: 20px;
  li{
    list-style-position: outside;
  }
}
</style>
