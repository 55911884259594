<template>
  <div class='m-1 mb-5 mt-3' style=''>
    <b-overlay
      :show='lookingForCompanies'
      rounded
      opacity='0.6'
      spinner-small
      spinner-variant='primary'
      class='d-inline-block w-100'
    >
      <b-modal
        ref='alert'
        scrollable
        hide-footer
        title='Atenção'
        header-bg-variant='info'
        header-text-variant='light'
      >
        <section class='modal-font-family'>
          <p>
            A impressão do arquivo em Excel esta sendo processada,
            clique no botão abaixo para acessar a página de downloads.
          </p>

          <router-link class='float-right router-link-text-decoration' :to="{ name: 'RelatoriosConsultaLegal' }">
            <base-button class='btn_export_modal'>
              <span class="label">Acessar</span>
            </base-button>
          </router-link>
        </section>
      </b-modal>

      <ProductAdd
        @clear-items-remove='listCompanies({ user: user.id })'>
      </ProductAdd>

      <modal-confirm-action
        variant='danger'
        refModal='deleteCompany'
        idModal='deleteCompany'
        :loading='loadingRemove'
        :description='descriptionRemove'
        :title='titleRemove'
        @confirmed='deleteCompany'
      />

      <modal-confirm-action
        variant='danger'
        refModal='deleteProduct'
        idModal='deleteProduct'
        :loading='loadingRemove'
        :description='descriptionRemove'
        :title='titleRemove'
        @confirmed='deleteProduct'
      />

      <modal-confirm-action
        variant='danger'
        refModal='changeCompany'
        idModal='changeCompany'
        :loading='loading'
        :description='descriptionChangeCompany'
        :descriptionAlert='descriptionAlertChangeCompany'
        :title='titleChangeCompany'
        @confirmed='confirmCompanyChange'
      />

      <b-modal
        ref='companyUpdate'
        id='companyUpdate'
        hide-footer
        header-bg-variant='info'
        header-text-variant='light'
        title='Atualizar empresa'
        size='lg'
      >
        <b-overlay
          :show='lookingForCompany'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block w-100'
          style='min-height: 250px'
        >
          <section class='modal-font-family' v-if='currentCompany'>
            <section>
              <label>Nome</label>
              <base-input-text
                v-model='company.name'
                placeholder='Digite o nome da empresa'
                class='label-input-modal'
                type='text'
              />
            </section>

            <section class='mt-3'>
              <label>Descrição da empresa</label>
              <base-text-area
                v-model='company.description'
                class='custom-textarea'
                placeholder='Digite a descrição da empresa'
                type='text'
              />
            </section>

            <section class='d-flex justify-content-between mt-4'>
              <base-button
                class='w-50 mr-3 btn-cancel'
                @click='hideModalCompanyUpdate()'
              >
                <span>Cancelar</span>
              </base-button>

              <b-overlay
                :show='loading'
                rounded
                opacity='0.6'
                spinner-small
                spinner-variant='primary'
                class='d-inline-block w-50'
              >
                <base-button
                  :disabled='disabled'
                  class='w-100 btn-add d-flex align-items-center justify-content-center'
                  @click='update()'
                >
                  <span class='mr-2'> Atualizar </span>
                </base-button>
              </b-overlay>
            </section>
          </section>

          <template v-slot:overlay>
            <div class='text-center loading-spinner'>
              <b-spinner
                style='width: 2rem; height: 2rem'
                variant='primary'
                label='Large Spinner'
              ></b-spinner>
              <h5 class='text-primary'>Buscando empresa...</h5>
            </div>
          </template>
        </b-overlay>
      </b-modal>

      <section v-if='companyLegalConsultation && companyLegalConsultation.length > 0'>
        <label for='selectedCompany'><strong>Selecione a empresa para visualizar</strong></label>
        <b-form-select
          id='selectedCompany'
          v-model='selectedCompany'
          :options='companyLegalConsultation'
          class='mb-3'
          style='height: 50px'
          value-field='_id'
          text-field='name'
          @change='setSelectedcompany()'
        >
          <template #first>
            <b-form-select-option :value='null' disabled>Selecione a empresa para visualizar</b-form-select-option>
          </template>
        </b-form-select>
      </section>

      <ul class='mt-0 w-100' v-if='companies.length > 0'>
        <li
          class='organization-chart'
          v-for='(company, i) in companies'
          :key='company._id'
        >
          <section style='' class=''>
            <section
              class='company d-flex align-items-center justify-content-between'
            >
              <section class='d-flex align-items-center justify-content-start'>
                <section>
                  <span class='name ml-3'>{{ company.name }}</span>
                </section>

                <section :class='calculateMetric(company).classStyle'>
                  <span class='metrics-text'>{{ calculateMetric(company).percentage }}% preenchido</span>
                </section>
              </section>

              <section class='d-flex align-items-center justify-content-between mr-3'>
                <section class='mr-4 d-flex'>
                  <b-dropdown
                    right
                    class='mr-2 btn-options'
                    menu-class='options'
                    variant='outline-dange'
                    no-flip
                    no-caret
                  >
                    <template #button-content>
                      <strong class='btn-options-text'>Opções</strong>
                    </template>

                    <b-dropdown-item
                      @click='showModalEditCompany(company, $event)'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <path
                          d='M13 3C13.2549 3.00028 13.5 3.09788 13.6854 3.27285C13.8707 3.44782 13.9822 3.68695 13.9972 3.94139C14.0121 4.19584 13.9293 4.44638 13.7657 4.64183C13.6021 4.83729 13.3701 4.9629 13.117 4.993L13 5H5V19H19V11C19.0003 10.7451 19.0979 10.5 19.2728 10.3146C19.4478 10.1293 19.687 10.0178 19.9414 10.0028C20.1958 9.98789 20.4464 10.0707 20.6418 10.2343C20.8373 10.3979 20.9629 10.6299 20.993 10.883L21 11V19C21.0002 19.5046 20.8096 19.9906 20.4665 20.3605C20.1234 20.7305 19.6532 20.9572 19.15 20.995L19 21H5C4.49542 21.0002 4.00943 20.8096 3.63945 20.4665C3.26947 20.1234 3.04284 19.6532 3.005 19.15L3 19V5C2.99984 4.49542 3.19041 4.00943 3.5335 3.63945C3.87659 3.26947 4.34684 3.04284 4.85 3.005L5 3H13ZM19.243 3.343C19.423 3.16365 19.6644 3.05953 19.9184 3.05177C20.1723 3.04402 20.4197 3.13322 20.6103 3.30125C20.8008 3.46928 20.9203 3.70355 20.9444 3.95647C20.9685 4.2094 20.8954 4.46201 20.74 4.663L20.657 4.758L10.757 14.657C10.577 14.8363 10.3356 14.9405 10.0816 14.9482C9.82767 14.956 9.58029 14.8668 9.38972 14.6988C9.19916 14.5307 9.07969 14.2964 9.0556 14.0435C9.03151 13.7906 9.10459 13.538 9.26 13.337L9.343 13.243L19.243 3.343Z'
                          fill='#262626'
                        />
                      </svg>

                      <span class='ml-2 dropdown-item-add-text'>Editar Empresa</span>
                    </b-dropdown-item>
                    <b-dropdown-divider class='mx-3'></b-dropdown-divider>

                    <b-dropdown-item
                      @click='showModalDeleteCompany(company, $event)'
                    >
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M9.94286 3.75C9.87466 3.75 9.80925 3.77634 9.76103 3.82322C9.71281 3.87011 9.68571 3.9337 9.68571 4V6.25H14.3143V4C14.3143 3.9337 14.2872 3.87011 14.239 3.82322C14.1907 3.77634 14.1253 3.75 14.0571 3.75H9.94286ZM15.8571 6.25V4C15.8571 3.53587 15.6675 3.09075 15.3299 2.76256C14.9924 2.43437 14.5345 2.25 14.0571 2.25H9.94286C9.46547 2.25 9.00763 2.43437 8.67006 2.76256C8.3325 3.09075 8.14286 3.53587 8.14286 4V6.25H4.80901C4.80359 6.24994 4.79816 6.24994 4.79271 6.25H3.77143C3.34538 6.25 3 6.58579 3 7C3 7.41421 3.34538 7.75 3.77143 7.75H4.09018L5.05735 19.0337C5.06637 19.7508 5.36332 20.4368 5.88561 20.9445C6.41607 21.4603 7.13553 21.75 7.88571 21.75H16.1143C16.8645 21.75 17.5839 21.4603 18.1144 20.9445C18.6367 20.4368 18.9336 19.7508 18.9426 19.0337L19.9098 7.75H20.2286C20.6546 7.75 21 7.41421 21 7C21 6.58579 20.6546 6.25 20.2286 6.25H19.2073C19.2018 6.24994 19.1964 6.24994 19.191 6.25H15.8571ZM5.63839 7.75L6.59733 18.9377C6.59911 18.9584 6.6 18.9792 6.6 19C6.6 19.3315 6.73546 19.6495 6.97658 19.8839C7.21769 20.1183 7.54472 20.25 7.88571 20.25H16.1143C16.4553 20.25 16.7823 20.1183 17.0234 19.8839C17.2645 19.6495 17.4 19.3315 17.4 19C17.4 18.9792 17.4009 18.9584 17.4027 18.9377L18.3616 7.75H5.63839ZM9.94286 10.25C10.3689 10.25 10.7143 10.5858 10.7143 11V17C10.7143 17.4142 10.3689 17.75 9.94286 17.75C9.51681 17.75 9.17143 17.4142 9.17143 17V11C9.17143 10.5858 9.51681 10.25 9.94286 10.25ZM14.0571 10.25C14.4832 10.25 14.8286 10.5858 14.8286 11V17C14.8286 17.4142 14.4832 17.75 14.0571 17.75C13.6311 17.75 13.2857 17.4142 13.2857 17V11C13.2857 10.5858 13.6311 10.25 14.0571 10.25Z'
                          fill='#CD0000'
                        />
                      </svg>

                      <span class='ml-2 dropdown-item-delete-text'>Excluir Empresa</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </section>

                <section>
                  <b-icon
                    font-scale='1.6'
                    icon='chevron-up'
                    id='popover-target-1'
                    color='#262626'
                    class='pointer transition'
                    :class="company.open ? 'rotate ' : ''"
                  >
                  </b-icon>
                </section>
              </section>
            </section>
          </section>

          <section class='mx-3 mt-2'>
            <hr />
          </section>

          <section class=''>
            <section class='mx-3 organization-chart-text-company-structure'>
              <p>GERAÇÃO DE RELATÓRIOS</p>
            </section>

            <section class='mx-3 d-flex align-items-center justify-content-between' >
              <section class='report d-flex align-items-center'>
                <b-popover
                  target="matrizRisco"
                  title=""
                  placement="topright"
                  triggers="hover top"
                >
                  <p class='pt-1'><strong>Disponível em breve</strong></p>
                </b-popover>
                <button
                  id='matrizRisco'
                  class='btn-reports-disabled'
                >
                  Matriz de Risco
                </button>
                <b-overlay
                  :show='exporting'
                  rounded
                  opacity='0.6'
                  spinner-small
                  spinner-variant='primary'
                  class='d-inline-block'
                >
                  <b-dropdown
                    menu-class='options'
                    class='btn-options'
                    no-flip
                    no-caret
                    variant='outline-dange'
                    left
                  >
                    <template #button-content>
                      <strong class='btn-options-text'>Inventário de Riscos Químico</strong>
                    </template>

                    <b-dropdown-item
                      @click='exportHierarchy(company._id)'
                    >
                      <span>Versão de desenvolvedor</span>
                    </b-dropdown-item>
                    <b-dropdown-divider class='mx-3'></b-dropdown-divider>

                    <b-dropdown-item @click="exportChemicalInventory(company._id, 'xlsx', 'chemicalInventoryXlsx')">
                      <span>Versão de excel</span>
                    </b-dropdown-item>
                    <b-dropdown-divider class='mx-3'></b-dropdown-divider>

                    <b-dropdown-item @click="exportChemicalInventory(company._id, 'pdf', 'chemicalInventoryPdf')">
                      <span>Versão de impressão</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-overlay>

                <b-popover
                  target="gro"
                  title=""
                  placement="topright"
                  triggers="hover top"
                >
                  <p class='pt-1'><strong>Disponível em breve</strong></p>
                </b-popover>
                <button
                  id='gro'
                  class='btn-reports-disabled'
                >
                  GRO
                </button>

                <b-popover
                  target="dashboardAnalitico"
                  title=""
                  placement="topright"
                  triggers="hover top"
                >
                  <p class='pt-1'><strong>Disponível em breve</strong></p>
                </b-popover>
                <button
                  id='dashboardAnalitico'
                  class='btn-reports-disabled'
                >
                  Dashboard Analítico
                </button>

                <b-overlay
                  :show='exporting'
                  rounded
                  opacity='0.6'
                  spinner-small
                  spinner-variant='primary'
                  class='d-inline-block'
                >
                <button
                    id='dashboardAnalitico'
                    class='btn-add p-1 px-2'
                    @click='exportChangeHistory(company._id)'
                  >
                    <span>Exportar histórico de alterações</span>
                  </button>
                </b-overlay>
              </section>

              <section class='d-flex align-items-center mb-3 available-soon'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.5 16.9L16.9 15.5C17 15.4 17.05 15.2833 17.05 15.15C17.05 15.0167 17 14.9 16.9 14.8L13.5 11.375C13.5667 11.1917 13.6167 11.0083 13.65 10.825C13.6833 10.6417 13.7 10.4333 13.7 10.2C13.7 9.25 13.3627 8.43767 12.688 7.763C12.0133 7.08833 11.2007 6.75067 10.25 6.75C9.96667 6.75 9.68333 6.78767 9.4 6.863C9.11667 6.93833 8.84167 7.04233 8.575 7.175L10.925 9.525L9.525 10.925L7.175 8.575C7.04167 8.84167 6.93767 9.11667 6.863 9.4C6.78833 9.68333 6.75067 9.96667 6.75 10.25C6.75 11.2 7.08767 12.0127 7.763 12.688C8.43833 13.3633 9.25067 13.7007 10.2 13.7C10.4167 13.7 10.621 13.6833 10.813 13.65C11.005 13.6167 11.1923 13.5667 11.375 13.5L14.8 16.9C14.9 17 15.0167 17.05 15.15 17.05C15.2833 17.05 15.4 17 15.5 16.9ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#022245"/>
                </svg>
                <span class='ml-2'>Disponível em breve</span>
              </section>
            </section>

            <section class='mx-3 mt-2'>
              <hr />
            </section>
            <section class='mx-3 my-4 organization-chart-text-company-structure d-flex align-items-start justify-content-between'>
              <p>ESTRUTURA DA EMPRESA</p>

              <section class='d-flex align-items-center justify-content-end' style='right: 0; top: 0'>
                <b-button
                  variant="outline-success"
                  @click='zoom -= .1'
                  class='d-flex align-items-center justify-content-end'
                >
                  <b-icon
                    icon='zoom-out'
                    aria-hidden='true'>
                  </b-icon>
                </b-button>

                <b-button
                  variant="outline-success"
                  class='ml-2 d-flex align-items-center justify-content-end'
                  @click='zoom += .1'
                >
                  <b-icon
                    icon='zoom-in'
                    aria-hidden='true'>
                  </b-icon>
                </b-button>
              </section>
            </section>

            <section  class='pb-3 scroll tree' id='scroll'>
              <ul id='scroll' :style="`zoom: ${zoom};width: fit-content;`" v-if='company.units && company.units.length > 0'>
                <li v-for='unit in company.units' :key='unit._id'>
                  <a>
                    <section class='organization-chart-name'>
                      <span>Unidade: {{ unit.name }}</span>
                    </section>
                  </a>

                  <ul v-if='unit.sectors && unit.sectors.length > 0'>
                    <li v-for='sector in unit.sectors' :key='sector._id'>
                      <a>
                        <section class='organization-chart-name'>
                          <span>Setor: {{ sector.name }}</span>
                        </section>
                      </a>

                      <ul v-if='sector.roles && sector.roles.length > 0'>
                        <li v-for='role in sector.roles' :key='role._id'>
                          <a>
                            <section class='organization-chart-name'>
                              <span> Cargo: {{ role.name }}</span>
                            </section>
                          </a>

                          <ul v-if='role.products && role.products.length > 0'>
                            <li v-for='product in role.products' :key='product._id'>
                              <a>
                                <section class='organization-chart-name'>
                                  <span> Produto: {{ product.name }}</span>
                                </section>
                              </a>

                              <ul
                                v-if='checkConditionsHaveBeenMet(product)'
                              >
                                <li>
                                  <a>
                                    <section class='organization-chart-name'>
                                      <span> Condições de uso do produto: {{ product.name }}</span>
                                    </section>
                                  </a>
                                </li>
                              </ul>
                              <ul v-else>
                                <li>
                                  <a style='border: none;'
                                     @click="completeHierarchy(company, 'condicoesDeUso', product._id)">
                                    <section class='organization-chart-not'>
                                      <span class='mr-2'>Clique aqui para <br /> cadastrar as condições <br /> de uso do produto</span>
                                      <svg width='25' height='24' viewBox='0 0 25 24' fill='none'
                                           xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                          d='M11.8359 17H13.8359V11H11.8359V17ZM12.8359 9C13.1193 9 13.3569 8.904 13.5489 8.712C13.7409 8.52 13.8366 8.28267 13.8359 8C13.8359 7.71667 13.7399 7.479 13.5479 7.287C13.3559 7.095 13.1186 6.99933 12.8359 7C12.5526 7 12.3149 7.096 12.1229 7.288C11.9309 7.48 11.8353 7.71733 11.8359 8C11.8359 8.28333 11.9319 8.521 12.1239 8.713C12.3159 8.905 12.5533 9.00067 12.8359 9ZM12.8359 22C11.4526 22 10.1526 21.7373 8.93594 21.212C7.71927 20.6867 6.66094 19.9743 5.76094 19.075C4.86094 18.175 4.1486 17.1167 3.62394 15.9C3.09927 14.6833 2.8366 13.3833 2.83594 12C2.83594 10.6167 3.0986 9.31667 3.62394 8.1C4.14927 6.88333 4.8616 5.825 5.76094 4.925C6.66094 4.025 7.71927 3.31267 8.93594 2.788C10.1526 2.26333 11.4526 2.00067 12.8359 2C14.2193 2 15.5193 2.26267 16.7359 2.788C17.9526 3.31333 19.0109 4.02567 19.9109 4.925C20.8109 5.825 21.5236 6.88333 22.0489 8.1C22.5743 9.31667 22.8366 10.6167 22.8359 12C22.8359 13.3833 22.5733 14.6833 22.0479 15.9C21.5226 17.1167 20.8103 18.175 19.9109 19.075C19.0109 19.975 17.9526 20.6877 16.7359 21.213C15.5193 21.7383 14.2193 22.0007 12.8359 22Z'
                                          fill='#D78300' />
                                      </svg>
                                    </section>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                          <ul v-else>
                            <li>
                              <a style='border: none;' @click="completeHierarchy(company, 'produto', role._id)">
                                <section class='organization-chart-not'>
                                  <span class='mr-2'>Clique aqui para <br /> cadastrar produto</span>
                                  <svg width='25' height='24' viewBox='0 0 25 24' fill='none'
                                       xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                      d='M11.8359 17H13.8359V11H11.8359V17ZM12.8359 9C13.1193 9 13.3569 8.904 13.5489 8.712C13.7409 8.52 13.8366 8.28267 13.8359 8C13.8359 7.71667 13.7399 7.479 13.5479 7.287C13.3559 7.095 13.1186 6.99933 12.8359 7C12.5526 7 12.3149 7.096 12.1229 7.288C11.9309 7.48 11.8353 7.71733 11.8359 8C11.8359 8.28333 11.9319 8.521 12.1239 8.713C12.3159 8.905 12.5533 9.00067 12.8359 9ZM12.8359 22C11.4526 22 10.1526 21.7373 8.93594 21.212C7.71927 20.6867 6.66094 19.9743 5.76094 19.075C4.86094 18.175 4.1486 17.1167 3.62394 15.9C3.09927 14.6833 2.8366 13.3833 2.83594 12C2.83594 10.6167 3.0986 9.31667 3.62394 8.1C4.14927 6.88333 4.8616 5.825 5.76094 4.925C6.66094 4.025 7.71927 3.31267 8.93594 2.788C10.1526 2.26333 11.4526 2.00067 12.8359 2C14.2193 2 15.5193 2.26267 16.7359 2.788C17.9526 3.31333 19.0109 4.02567 19.9109 4.925C20.8109 5.825 21.5236 6.88333 22.0489 8.1C22.5743 9.31667 22.8366 10.6167 22.8359 12C22.8359 13.3833 22.5733 14.6833 22.0479 15.9C21.5226 17.1167 20.8103 18.175 19.9109 19.075C19.0109 19.975 17.9526 20.6877 16.7359 21.213C15.5193 21.7383 14.2193 22.0007 12.8359 22Z'
                                      fill='#D78300' />
                                  </svg>
                                </section>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul v-else>
                        <li>
                          <a style='border: none;' @click="completeHierarchy(company, 'cargo', sector._id)">
                            <section class='organization-chart-not'>
                              <span class='mr-2'>Clique aqui para <br /> cadastrar cargo</span>
                              <svg width='25' height='24' viewBox='0 0 25 24' fill='none'
                                   xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M11.8359 17H13.8359V11H11.8359V17ZM12.8359 9C13.1193 9 13.3569 8.904 13.5489 8.712C13.7409 8.52 13.8366 8.28267 13.8359 8C13.8359 7.71667 13.7399 7.479 13.5479 7.287C13.3559 7.095 13.1186 6.99933 12.8359 7C12.5526 7 12.3149 7.096 12.1229 7.288C11.9309 7.48 11.8353 7.71733 11.8359 8C11.8359 8.28333 11.9319 8.521 12.1239 8.713C12.3159 8.905 12.5533 9.00067 12.8359 9ZM12.8359 22C11.4526 22 10.1526 21.7373 8.93594 21.212C7.71927 20.6867 6.66094 19.9743 5.76094 19.075C4.86094 18.175 4.1486 17.1167 3.62394 15.9C3.09927 14.6833 2.8366 13.3833 2.83594 12C2.83594 10.6167 3.0986 9.31667 3.62394 8.1C4.14927 6.88333 4.8616 5.825 5.76094 4.925C6.66094 4.025 7.71927 3.31267 8.93594 2.788C10.1526 2.26333 11.4526 2.00067 12.8359 2C14.2193 2 15.5193 2.26267 16.7359 2.788C17.9526 3.31333 19.0109 4.02567 19.9109 4.925C20.8109 5.825 21.5236 6.88333 22.0489 8.1C22.5743 9.31667 22.8366 10.6167 22.8359 12C22.8359 13.3833 22.5733 14.6833 22.0479 15.9C21.5226 17.1167 20.8103 18.175 19.9109 19.075C19.0109 19.975 17.9526 20.6877 16.7359 21.213C15.5193 21.7383 14.2193 22.0007 12.8359 22Z'
                                  fill='#D78300' />
                              </svg>
                            </section>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul v-else>
                    <li>
                      <a style='border: none;' @click="completeHierarchy(company, 'setor', unit._id)">
                        <section class='organization-chart-not'>
                          <span class='mr-2'>Clique aqui para <br /> cadastrar setor</span>
                          <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M11.8359 17H13.8359V11H11.8359V17ZM12.8359 9C13.1193 9 13.3569 8.904 13.5489 8.712C13.7409 8.52 13.8366 8.28267 13.8359 8C13.8359 7.71667 13.7399 7.479 13.5479 7.287C13.3559 7.095 13.1186 6.99933 12.8359 7C12.5526 7 12.3149 7.096 12.1229 7.288C11.9309 7.48 11.8353 7.71733 11.8359 8C11.8359 8.28333 11.9319 8.521 12.1239 8.713C12.3159 8.905 12.5533 9.00067 12.8359 9ZM12.8359 22C11.4526 22 10.1526 21.7373 8.93594 21.212C7.71927 20.6867 6.66094 19.9743 5.76094 19.075C4.86094 18.175 4.1486 17.1167 3.62394 15.9C3.09927 14.6833 2.8366 13.3833 2.83594 12C2.83594 10.6167 3.0986 9.31667 3.62394 8.1C4.14927 6.88333 4.8616 5.825 5.76094 4.925C6.66094 4.025 7.71927 3.31267 8.93594 2.788C10.1526 2.26333 11.4526 2.00067 12.8359 2C14.2193 2 15.5193 2.26267 16.7359 2.788C17.9526 3.31333 19.0109 4.02567 19.9109 4.925C20.8109 5.825 21.5236 6.88333 22.0489 8.1C22.5743 9.31667 22.8366 10.6167 22.8359 12C22.8359 13.3833 22.5733 14.6833 22.0479 15.9C21.5226 17.1167 20.8103 18.175 19.9109 19.075C19.0109 19.975 17.9526 20.6877 16.7359 21.213C15.5193 21.7383 14.2193 22.0007 12.8359 22Z'
                              fill='#D78300' />
                          </svg>
                        </section>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <ul v-else>
                <li>
                  <a style='border: none;' @click="completeHierarchy(company, 'unidade', company._id)">
                    <section class='organization-chart-not'>
                      <span class='mr-2'>Clique aqui para <br /> cadastrar unidade</span>
                      <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M11.8359 17H13.8359V11H11.8359V17ZM12.8359 9C13.1193 9 13.3569 8.904 13.5489 8.712C13.7409 8.52 13.8366 8.28267 13.8359 8C13.8359 7.71667 13.7399 7.479 13.5479 7.287C13.3559 7.095 13.1186 6.99933 12.8359 7C12.5526 7 12.3149 7.096 12.1229 7.288C11.9309 7.48 11.8353 7.71733 11.8359 8C11.8359 8.28333 11.9319 8.521 12.1239 8.713C12.3159 8.905 12.5533 9.00067 12.8359 9ZM12.8359 22C11.4526 22 10.1526 21.7373 8.93594 21.212C7.71927 20.6867 6.66094 19.9743 5.76094 19.075C4.86094 18.175 4.1486 17.1167 3.62394 15.9C3.09927 14.6833 2.8366 13.3833 2.83594 12C2.83594 10.6167 3.0986 9.31667 3.62394 8.1C4.14927 6.88333 4.8616 5.825 5.76094 4.925C6.66094 4.025 7.71927 3.31267 8.93594 2.788C10.1526 2.26333 11.4526 2.00067 12.8359 2C14.2193 2 15.5193 2.26267 16.7359 2.788C17.9526 3.31333 19.0109 4.02567 19.9109 4.925C20.8109 5.825 21.5236 6.88333 22.0489 8.1C22.5743 9.31667 22.8366 10.6167 22.8359 12C22.8359 13.3833 22.5733 14.6833 22.0479 15.9C21.5226 17.1167 20.8103 18.175 19.9109 19.075C19.0109 19.975 17.9526 20.6877 16.7359 21.213C15.5193 21.7383 14.2193 22.0007 12.8359 22Z'
                          fill='#D78300' />
                      </svg>
                    </section>
                  </a>
                </li>
              </ul>
            </section>
          </section>
        </li>
        <br />
      </ul>

      <ul class='my-3 text-center' v-if='!companyLegalConsultation || companyLegalConsultation.length <= 0'>
        <hr />
        <h3>O usuário ainda não tem empresa cadastrada!</h3>
      </ul>

      <section class='w-100 mb-5 mt-3'>
        <b-pagination
          v-if='companiesMeta.total > companyPerPage'
          align='end'
          :per-page='companyPerPage'
          v-model='companiesMeta.skip'
          :total-rows='companiesMeta.total'
          first-text='⏮'
          prev-text='⏪'
          next-text='⏩'
          last-text='⏭'
          @page-click='setPage'
        >
        </b-pagination>
      </section>

      <template v-slot:overlay>
        <div class='text-center loading-spinner'>
          <b-spinner
            style='width: 3rem; height: 3rem'
            variant='primary'
            label='Large Spinner'
          ></b-spinner>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalConfirmAction from '@/components/ModalConfirmAction.vue'
import eventBus from '@/utils/eventBus'
import ProductAdd from '@/components/LegalSearch/Modal/ProductAdd.vue'
import { optionsVentilationIdentified } from '@/mocks/optionsVentilationIdentified'
import { optionsExposureTime } from '@/mocks/optionsExposureTime'
import { optionsFrequencyUse } from '@/mocks/optionsFrequencyUse'
import { optionsQuantityUsed } from '@/mocks/optionsQuantityUsed'
import { physicalProductStates } from '@/mocks/physicalProductStates'
import { forEach } from 'underscore'
import legalSearch from '@/views/LegalSearch.vue'
import BaseButton from '@/components/Base/BaseButton.vue'
import { tr } from 'date-fns/locale'
import moment from 'moment/moment'

export default {
  name: 'ManageCompanies',
  components: { BaseButton, ProductAdd, ModalConfirmAction },
  data() {
    return {
      companyLegalConsultation: [],
      selectedCompany: null,
      hierarquia: null,
      hierarquiaId: null,
      titleRemove: '',
      descriptionRemove: '',
      titleChangeCompany: '',
      descriptionChangeCompany: '',
      descriptionAlertChangeCompany: '',
      loadingRemove: false,
      loading: false,
      lookingForCompany: false,
      lookingForCompanies: false,
      currentCompany: null,
      zoom: 1,
      legalSearchesId: [],
      currentProduct: null,
      productId: '',
      company: {
        name: '',
        description: '',
        _id: null
      },
      optionsVentilationIdentified: [],
      optionsExposureTime: [],
      optionsFrequencyUse: [],
      optionsQuantityUsed: [],
      physicalProductStates: []
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Gerenciar empresas',
      subPage: {
        name: 'Gerencie, edite ou exclua empresas e agentes',
        active: false
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },

  async mounted() {
    try {
      Object.assign(this.optionsVentilationIdentified, optionsVentilationIdentified)
      this.optionsVentilationIdentified = optionsVentilationIdentified
      Object.assign(this.optionsExposureTime, optionsExposureTime)
      this.optionsExposureTime = optionsExposureTime
      Object.assign(this.optionsFrequencyUse, optionsFrequencyUse)
      this.optionsFrequencyUse = optionsFrequencyUse
      Object.assign(this.optionsQuantityUsed, optionsQuantityUsed)
      this.optionsQuantityUsed = optionsQuantityUsed
      Object.assign(this.physicalProductStates, physicalProductStates)
      this.physicalProductStates = physicalProductStates
      await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)
      await this.init()
      const element = document.getElementById('scroll');
      const width = element.clientWidth;
      element.scrollLeft += width*2;
    } catch (e) {
    }
  },

  computed: {
    xlsxLink() {
      return `${process.env.VUE_APP_BASE_URL}/xlsx/${this.user.id}/ltcat`
    },
    user() {
      return this.$store.getters['user/current']
    },
    ...mapGetters('companyLegalConsultation', {
      companies: 'companies',
      companiesMeta: 'companiesMeta',
      companyPerPage: 'companyPerPage',
      importedCompany: 'importedCompany'
    }),
    disabled() {
      return !(this.company.name && this.company.description)
    },
    exporting() {
      return this.$store.getters['legalSearch/exporting']
    },
  },

  methods: {
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('companyLegalConsultation', {
      handlePaginationCompanyLegalConsultation: 'handlePaginationCompanyLegalConsultation',
      handlePaginationReportCompanyLegalConsultation: 'handlePaginationReportCompanyLegalConsultation',
      handleGetByIdCompanyLegalConsultation: 'handleGetByIdCompanyLegalConsultation',
      handleUpdatecompanyLegalConsultation: 'handleUpdatecompanyLegalConsultation',
      handleDeletecompanyLegalConsultation: 'handleDeletecompanyLegalConsultation',
      handleUpdateOpencompanyLegalConsultation: 'handleUpdateOpencompanyLegalConsultation',
      handleUnmarkAllAsLastImportedCompanyLegalConsultation: 'handleUnmarkAllAsLastImportedCompanyLegalConsultation',
      handleGetLastImportedCompanyLegalConsultation: 'handleGetLastImportedCompanyLegalConsultation',
      handleClear: 'handleClear',
      handleMarkAsLastImportedCompanyLegalConsultation: 'handleMarkAsLastImportedCompanyLegalConsultation',
      handleOpenHierarchyCompleteCompanyLegalConsultation: 'handleOpenHierarchyCompleteCompanyLegalConsultation',
      handleGetCompanyLegalConsultation: 'handleGetCompanyLegalConsultation',
      handleUpdateViewInManageCompany: 'handleUpdateViewInManageCompany',
    }),
    ...mapActions('productLegalConsultation', {
      handleDeleteByIdProductLegalConsultation: 'handleDeleteByIdProductLegalConsultation'
    }),
    ...mapActions('unit', {
      handleOpen: 'handleOpen',
      handleDelete: 'handleDelete'
    }),
    ...mapActions('sector', {
      handleOpenSector: 'handleOpen',
      handleDeleteSector: 'handleDelete'
    }),
    ...mapActions('role', {
      handleOpenRole: 'handleOpen',
      handleDeleteRole: 'handleDelete'
    }),
    ...mapActions('freeModeAgents', {
      handleCreateFreeModeAgents: 'handleCreateFreeModeAgents',
      handleAddAgentsFreeModeAgents: 'handleAddAgentsFreeModeAgents'
    }),
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    ...mapActions('legalSearch', {
      exportingExcel: 'exportingExcel',
    }),
    async exportHierarchy(companyId) {
      try {
        const path = this.$router.resolve({ path: `gerenciar-empresas/hierarquia/${companyId}`})
        window.open(path.href, '_blank')
      } catch (e) {

      }
    },

    async exportChemicalInventory(companyId, format, type) {
      try {
        await this.exportingExcel(true);
        const payload = {
          user: this.user.id,
          type,
          format,
          job: 'chemicalInventory',
          parameters: {
            companyId,
          }
        }
        const { data, status } = await this.handleCreateReportDownloads(payload)

        let message = data.message
        let variant = 'danger'
        await this.exportingExcel(false);
        if(status === 201){
          message = data.message;
          variant = 'success';
          this.$refs['alert'].show()
          return
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        });
      } catch (e) {

      }
    },

    async exportChangeHistory(companyId) {
     try {
       await this.exportingExcel(true);
       const payload = {
         user: this.user.id,
         type: "logsTabxlsx",
         format: 'xlsx',
         job: 'logsTab',
         parameters: {
           companyId,
         }
       }
       const { data, status } = await this.handleCreateReportDownloads(payload)

       let message = data.message
       let variant = 'danger'
       await this.exportingExcel(false);
       if(status === 201){
         message = data.message;
         variant = 'success';
         this.$refs['alert'].show()
         return
       }
       this.$bvToast.toast(message, {
         title: 'Atenção',
         variant: variant,
         toaster: 'b-toaster-top-center',
         autoHideDelay: 5000,
       });
     } catch (e) {

     }
    },

    async init() {
      this.lookingForCompanies = true;
      await this.handleClear()
      const { data } = await this.handleGetCompanyLegalConsultation(this.user.id)
      const { companies } = data
      this.companyLegalConsultation = companies;
      const company = this.companyLegalConsultation.find((x) => x.viewInManageCompany)
      if (this.companyLegalConsultation && (this.companyLegalConsultation.length === 1 || (company && company._id))) {
        this.selectedCompany = company ? company._id : this.companyLegalConsultation[0]._id
        await this.listCompanies({
          companyId: this.selectedCompany,
          user: this.user.id
        })
      }
      this.lookingForCompanies = false;
    },

    checkConditionsHaveBeenMet(product) {
      return product.termsofuses && product.termsofuses._id && product.termsofuses.temperature && product.termsofuses.exposedWorkers && product.termsofuses.quantityUsed &&
        product.termsofuses.frequencyUse && product.termsofuses.exposureTime && product.termsofuses.ventilationWorkEnvironmentGeneral &&
        product.termsofuses.ventilationInstalledSpecificallyForTheProductionProcess &&  product.termsofuses.physicalStateProduct && product.termsofuses.description
    },

    async completeHierarchy(company, hierarquia, hierarchicaLevelId) {
      this.hierarquia = hierarquia
      this.hierarquiaId = hierarchicaLevelId
      this.currentCompany = company
      if (this.importedCompany && this.importedCompany._id && this.importedCompany._id === this.currentCompany._id) {
        this.lookingForCompanies = true
        await this.handleOpenHierarchyCompleteCompanyLegalConsultation(this.currentCompany._id)
        this.lookingForCompanies = false
        await this.toGro()
        return
      }
      if (this.importedCompany && this.importedCompany._id && this.importedCompany._id !== this.currentCompany._id) {
        this.titleChangeCompany = 'Completar hierarquia'
        this.descriptionAlertChangeCompany =
          `<strong class='text-danger'>Atenção: </strong> Caso queira completar a hierarquia da empresa <strong>${this.currentCompany.name}</strong>,
                os agentes da consulta legal serão alterados da empresa <strong>${this.importedCompany.name}</strong> para a empresa <strong>${this.currentCompany.name}</strong>.
                <br/><br/><strong>Nenhuma informação da empresa atual será perdida.</strong>`
      } else if (!this.importedCompany || !this.importedCompany._id) {
        this.descriptionAlertChangeCompany =
          `<strong class='text-danger'>Atenção: </strong> Caso queira completar a hierarquia da empresa <strong>${this.currentCompany.name}</strong>,
                os agentes da consulta legal serão alterados para a empresa <strong>${this.currentCompany.name}</strong>.
                <br/><br/><strong>Nenhuma informação da empresa atual será perdida.</strong>`
      }
      this.$bvModal.show('changeCompany')
    },

    async toGro() {
      await this.$router.push({
        path: '/web',
        query: {
          aba: 'Gro',
          hierarquia: this.hierarquia,
          id: this.hierarquiaId
        }
      })
    },

    calculateMetric(company) {
      let minimoCamposSerPreenchidos = 0
      let camposPreenchidos = 1
      let classStyle = 'metrics-red'

      if (!company.units || company.units.length === 0) {
        minimoCamposSerPreenchidos += 14
      }

      company.units.forEach((unit) => {
        if (!unit.sectors || unit.sectors.length === 0) {
          minimoCamposSerPreenchidos += 13
        }
        minimoCamposSerPreenchidos++
        unit.sectors.forEach((sector) => {
          if (!sector.roles || sector.roles.length === 0) {
            minimoCamposSerPreenchidos += 12
          }
          minimoCamposSerPreenchidos++
          sector.roles.forEach((role) => {
            if (!role.products || role.products.length === 0) {
              minimoCamposSerPreenchidos += 11
            }
            minimoCamposSerPreenchidos++
            role.products.forEach((product) => {
              if (product.termsofuses && product.termsofuses && product.termsofuses._id) {
                let toFill = 0
                let filled = 0

                if (product.termsofuses.temperature) {
                  filled++
                } else {
                  toFill++
                }

                if (product.termsofuses.exposedWorkers) {
                  filled++
                } else {
                  toFill++
                }

                if (product.termsofuses.quantityUsed) {
                  filled++
                } else {
                  toFill++
                }

                if (product.termsofuses.frequencyUse) {
                  filled++
                } else {
                  toFill++
                }

                if (product.termsofuses.ventilationWorkEnvironmentGeneral) {
                  filled++
                } else {
                  toFill++
                }

                if (product.termsofuses.ventilationInstalledSpecificallyForTheProductionProcess) {
                  filled++
                } else {
                  toFill++
                }

                if (product.termsofuses.ventilationIdentified) {
                  filled++
                } else {
                  toFill++
                }

                if (product.termsofuses.physicalStateProduct) {
                  filled++
                } else {
                  toFill++
                }

                if (product.termsofuses.physicalStateProduct == 2 || product.termsofuses.degreeOfDust) {
                  filled++
                } else {
                  toFill++
                }

                if (product.termsofuses.description) {
                  filled++
                } else {
                  toFill++
                }
                minimoCamposSerPreenchidos += toFill + filled
              } else {
                minimoCamposSerPreenchidos += 9
              }
              minimoCamposSerPreenchidos += product.legalSearches.length
              minimoCamposSerPreenchidos++
            })
          })
        })
      })

      company.units.forEach((unit) => {
        camposPreenchidos++
        unit.sectors.forEach((sector) => {
          camposPreenchidos++
          sector.roles.forEach((role) => {
            camposPreenchidos++
            role.products.forEach((product) => {
              camposPreenchidos += product.legalSearches.filter((legalSearch) => legalSearch.percentage).length

              if (product.termsofuses && product.termsofuses && product.termsofuses._id) {
                if (product.termsofuses.temperature) {
                  camposPreenchidos++
                }

                if (product.termsofuses.exposedWorkers) {
                  camposPreenchidos++
                }

                if (product.termsofuses.quantityUsed) {
                  camposPreenchidos++
                }

                if (product.termsofuses.frequencyUse) {
                  camposPreenchidos++
                }

                if (product.termsofuses.exposureTime) {
                  camposPreenchidos++
                }

                if (product.termsofuses.ventilationWorkEnvironmentGeneral) {
                  camposPreenchidos++
                }

                if (product.termsofuses.ventilationInstalledSpecificallyForTheProductionProcess) {
                  camposPreenchidos++
                }

                if (product.termsofuses.physicalStateProduct) {
                  camposPreenchidos++
                }

                if (product.termsofuses.physicalStateProduct == 2 || product.termsofuses.degreeOfDust) {
                  camposPreenchidos++
                }

                if (product.termsofuses.description) {
                  camposPreenchidos++
                }
              }
            })
          })
        })
      })

      /*console.log(`minimoCamposSerPreenchidos: ${minimoCamposSerPreenchidos}`)
      console.log(`camposPreenchidos: ${camposPreenchidos}`)*/
      const percentage = ((camposPreenchidos / minimoCamposSerPreenchidos) * 100).toFixed(0)
      if (percentage >= 80) {
        classStyle = 'metrics-green'
      } else if (percentage >= 55) {
        classStyle = 'metrics-yellow'
      }
      return { percentage, classStyle }
    },

    async showModalEditCompany(company, event) {
      try {
        event.stopPropagation()
        this.$refs.companyUpdate.show()
        this.lookingForCompany = true
        this.loading = false
        const { data } = await this.handleGetByIdCompanyLegalConsultation(
          company._id
        )
        this.lookingForCompany = false
        this.company = data.company
        this.currentCompany = company
      } catch (e) {
      }
    },

    async checkCompleteHierarchy(company, event) {
      try {
        event.stopPropagation()
        await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)
        this.currentCompany = company
        let completeHierarchy = true

        for (const unit of company.units) {
          if (!unit._id) {
            completeHierarchy = false
            break
          }
          for (const sector of unit.sectors) {
            if (!sector._id) {
              completeHierarchy = false
              break
            }
            for (const role of sector.roles) {
              if (!role._id) {
                completeHierarchy = false
                break
              }

              for (const product of role.products.filter((x) => x.role)) {
                if (!product._id) {
                  completeHierarchy = false
                  break
                }

                if (product.legalSearches.filter((x) => x.percentage).length !== product.legalSearches.length) {
                  completeHierarchy = false
                  break
                }

                if (
                  !product.termsofuses || !product.termsofuses._id || !product.termsofuses.temperature ||
                  !product.termsofuses.exposedWorkers || !product.termsofuses.quantityUsed || !product.termsofuses.frequencyUse ||
                  !product.termsofuses.exposureTime || !product.termsofuses.ventilationIdentified || !product.termsofuses.physicalStateProduct || !product.termsofuses.description
                ) {
                  completeHierarchy = false
                  break
                }
              }
            }
          }
        }

        if (!completeHierarchy) {
          if (this.importedCompany && this.importedCompany._id) {
            this.descriptionChangeCompany =
              `A empresa <strong>${this.currentCompany.name}</strong> não está com a hierarquia 100% preenchida. <br/>
                 Deseja completar a hirearquia da empresa <strong>${this.currentCompany.name}? `

            if (this.importedCompany._id !== this.currentCompany._id) {
              this.descriptionAlertChangeCompany =
                `<strong class='text-danger'>Atenção: </strong> Caso queira completar a hierarquia da empresa <strong>${this.currentCompany.name}</strong>,
                os agentes da consulta legal serão alterados da empresa <strong>${this.importedCompany.name}</strong> para a empresa <strong>${this.currentCompany.name}</strong>.
                <br/><br/><strong>Nenhuma informação da empresa atual será perdida.</strong>`
            }
          } else {
            this.descriptionChangeCompany =
              `A empresa <strong>${this.currentCompany.name}</strong> não está com a hierarquia 100% preenchida. <br/>
                 Deseja completar a hirearquia da empresa <strong>${this.currentCompany.name}? `

            this.descriptionAlertChangeCompany = `<strong class='text-danger'>Atenção: </strong> o modo de consulta será alterado para a empresa <strong>${this.currentCompany.name}</strong>
            <br/><br/><strong>Nenhuma informação dos agentes na consulta legal serão perdidos.</strong>`
          }
          this.$bvModal.show('changeCompany')
        }

      } catch (e) {
      }
    },

    async confirmCompanyChange() {
      this.legalSearchesId = []
      this.loading = true
      await this.handleOpenHierarchyCompleteCompanyLegalConsultation(this.currentCompany._id)
      if (this.importedCompany && this.importedCompany._id) {
        if (this.importedCompany._id === this.currentCompany._id) {
          await this.toGro()
          return
        }
        const { importSummary } = (await this.$store.dispatch(`legalSearch/importAgentsCompany`, {
            user: this.user.id,
            legalSearches: this.currentCompany.legalSearches
          })
        ).data
        this.legalSearchesId.push(...importSummary.legalSearchesAddId)
        await this.handleUnmarkAllAsLastImportedCompanyLegalConsultation(this.user.id)
        await this.handleMarkAsLastImportedCompanyLegalConsultation(
          {
            user: this.user.id,
            companyLegalConsultationId: this.currentCompany._id
          }
        )
        await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)
        await this.$store.dispatch('legalSearch/notRemove', this.legalSearchesId)
      } else {
        this.loading = true

        await this.handleCreateFreeModeAgents(
          {
            user: this.user.id,
            agents: []
          }
        )

        const { importSummary } = (await this.$store.dispatch(`legalSearch/importAgentsCompany`, {
            user: this.user.id,
            legalSearches: this.currentCompany.legalSearches
          })
        ).data
        this.legalSearchesId.push(...importSummary.legalSearchesAddId)
        await this.handleUnmarkAllAsLastImportedCompanyLegalConsultation(this.user.id)
        await this.handleMarkAsLastImportedCompanyLegalConsultation(
          {
            user: this.user.id,
            companyLegalConsultationId: this.currentCompany._id
          }
        )
        await this.handleGetLastImportedCompanyLegalConsultation(this.user.id)
        await this.$store.dispatch('legalSearch/notRemove', this.legalSearchesId)
      }
      this.loading = false
      this.loading = false
      this.$bvModal.hide('changeCompany')
      await this.toGro()
    },

    async showModalEditProduct(productId, event) {
      try {
        event.stopPropagation()
        eventBus.$emit('showModalAddProduct', productId)
      } catch (e) {
      }
    },

    async showModalDeleteCompany(company, event) {
      try {
        event.stopPropagation()
        this.$bvModal.show('deleteCompany')
        this.titleRemove = `Exluir empresa`
        this.descriptionRemove = `Essa ação vai exluir a empresa <strong>${
          company.name
        }</strong>, ${
          company.lastImported
            ? 'os agentes da empresa, produtos e os agentes da consulta legal.<br/><br/>'
            : 'os agentes da empresa e produtos.<br/> <br/>'
        } deseja realmente finalizar esta ação?`
        this.lookingForCompany = true
        this.loading = false
        const { data } = await this.handleGetByIdCompanyLegalConsultation(
          company._id
        )
        this.lookingForCompany = false
        this.company = data.company
        this.currentCompany = company
      } catch (e) {
      }
    },

    async showModalDeleteProduct(product, event) {
      try {
        event.stopPropagation()
        this.$bvModal.show('deleteProduct')
        this.titleRemove = `Exluir produto`
        this.descriptionRemove = `Essa ação vai exluir o produto <strong>${product.name}</strong>, e os agentes. <br/> deseja realmente finalizar esta ação?`
        this.currentProduct = product
      } catch (e) {
      }
    },

    async deleteProduct() {
      try {
        this.loadingRemove = true
        const { data, status } =
          await this.handleDeleteByIdProductLegalConsultation(
            this.currentProduct._id
          )
        this.loadingRemove = false
        this.$bvModal.hide('deleteProduct')
        let message = data.message
        let variant = 'success'
        if (status !== 200) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        await this.listCompanies({
          user: this.user.id
        })
      } catch (e) {
      }
    },

    async update() {
      try {
        this.loading = true
        const { data, status } =
          await this.handleUpdatecompanyLegalConsultation(this.company)
        this.loading = false
        this.hideModalCompanyUpdate()
        let message = data.message
        let variant = 'success'
        if (status !== 200) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        await this.listCompanies({
          user: this.user.id
        })
      } catch (e) {
      }
    },

    async deleteCompany() {
      try {
        this.loadingRemove = true
        const { data, status } =
          await this.handleDeletecompanyLegalConsultation(
            this.currentCompany._id
          )
        this.loadingRemove = false
        this.$bvModal.hide('deleteCompany')
        let message = data.message
        let variant = 'success'
        if (status !== 200) {
          message = data.errors
          variant = 'danger'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.selectedCompany = null;
        await this.init()
      } catch (e) {
      }
    },

    hideModalCompanyUpdate() {
      this.$refs.companyUpdate.hide()
    },

    async detailCompany(company) {
      try {
        this.lookingForCompanies = true
        await this.handleUpdateOpencompanyLegalConsultation(company)
        await this.listCompanies({
          ...this.companiesMeta,
          user: this.user.id
        })
      } catch (e) {
      }
    },

    async detailUnit(unit) {
      try {
        if (unit._id) {
          this.lookingForCompanies = true
          await this.handleOpen(unit._id)
          await this.listCompanies({
            ...this.companiesMeta,
            user: this.user.id
          })
          this.lookingForCompanies = false
        }
      } catch (e) {
      }
    },

    async detailSector(sector) {
      try {
        if (sector._id) {
          this.lookingForCompanies = true
          await this.handleOpenSector(sector._id)
          await this.listCompanies({
            ...this.companiesMeta,
            user: this.user.id
          })
          this.lookingForCompanies = false
        }
      } catch (e) {
      }
    },

    async detailRole(role) {
      try {
        if (role._id) {
          this.lookingForCompanies = true
          await this.handleOpenRole(role._id)
          await this.listCompanies({
            ...this.companiesMeta,
            user: this.user.id
          })
          this.lookingForCompanies = false
        }
      } catch (e) {
      }
    },

    async setSelectedcompany() {
      try {
        this.lookingForCompanies = true

        await this.handleUpdateViewInManageCompany({
          userId: this.user.id,
          companyId: this.selectedCompany
        })

        await this.listCompanies({
          companyId: this.selectedCompany,
          user: this.user.id
        })
      } catch (e) {

      }
    },

    async listCompanies(payload) {
      try {
        this.lookingForCompanies = true
        const params = {
          ...this.companiesMeta,
          ...payload,
        }
        await this.handlePaginationCompanyLegalConsultation(params)
        this.lookingForCompanies = false
      } catch (e) {
      }
    },

    groupAgentsByProduct(legalSearches) {
      const legalConsultationAgentsGroupedByProduct = []

      const legalSearchGroupByProduct = legalSearches.reduce((group, agent) => {
        const productObject =
          agent.productObject && agent.productObject._id
            ? agent.productObject._id
            : `-`
        group[productObject] = group[productObject] ? group[productObject] : []
        group[productObject].push(agent)
        return group
      }, {})

      const keys = Object.keys(legalSearchGroupByProduct)
      keys.forEach((legalSearchGroupBy) => {
        legalConsultationAgentsGroupedByProduct.push({
          product:
            legalSearchGroupBy !== `-`
              ? legalSearchGroupByProduct[legalSearchGroupBy].find(
                (agent) =>
                  agent.productObject._id.toString() === legalSearchGroupBy
              ).productObject
              : { _id: `-`, name: ' ', description: '' },

          legalSearches: legalSearchGroupByProduct[legalSearchGroupBy].map(
            (x, index) => {
              const legalSearch = x
              legalSearch.index = index
              return legalSearch
            }
          )
        })
      })
      return legalConsultationAgentsGroupedByProduct
    },

    async setPage(event, page) {
      await this.handleClear()
      await this.listCompanies({
        user: this.user.id,
        skip: page
      })
    }
  }
}
</script>

<style lang='scss' scoped>

.tree ul {
  padding-top: 20px;
  position: relative;
  -transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
  -transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1.5px solid #696969;
  width: 50%;
  height: 20px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1.5px solid #808080;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 1.5px solid #808080;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1.5px solid #808080;
  width: 0;
  height: 20px;
}

.tree li a {
  text-decoration: none;
  color: #262626;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center !important;
  display: inline-block;
  border-radius: 6px;
  border: 1px solid #808080;
  -transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li a:hover,
.tree li a:hover + ul li a {
  .organization-chart-not {
    background: #D78300 !important;
    color: #FFFFFF !important;

    svg {
      path {
        fill: #FFFFFF !important;
      }
    }
  }

  background: var(--navy-blue);
  color: #FFFFFF;
}

.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

.scroll{
  overflow: auto!important;
  text-align: center!important;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background: #022245;
    border-radius: 20px;
    border: 3px solid #ffffff;
    -webkit-box-shadow: inset 0 0 6px #022245;
  }
}
.organization-chart {
  border: 1px solid #808080;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.organization-chart-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #262626;
}

.organization-chart-text-company-structure {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #808080;
}
.btn-reports-disabled{
  border-radius: 4px;
  background: #BDBDBD;
  border: none;
  padding: 8px 10px;
  color: #FFFFFF;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  margin-right: 20px;
}

.available-soon{
  background: #DBECF4;
  border-radius: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--navy-blue);
  padding: 10px 15px;
}

.organization-chart-sub-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #262626;
}

.organization-chart-name {
  min-height: 60px;
  min-width: 200px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  word-break: break-word;
  cursor: pointer;
}

.organization-chart-not {
  cursor: pointer;
  min-height: 60px;
  min-width: 200px;
  max-width: 300px;
  color: #D78300 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border: 1px solid #D78300 !important;
  border-radius: 6px;
  background: #D783001A;
}


.loading-spinner {
  margin-top: 250px;
}

.rotate {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.transition {
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  -ms-transition: -ms-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
}

.btn-cancel {
  background: transparent !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 6.25px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.metrics-green {
  border-radius: 20px;
  background: rgba(0, 119, 37, 0.25) !important;
  margin-left: 20px;
  padding: 5px 20px;

  .metrics-text {
    color: #007725 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.metrics-yellow {
  border-radius: 20px;
  background: rgba(215, 131, 0, 0.25);
  margin-left: 20px;
  padding: 5px 20px;

  .metrics-text {
    color: #D78300 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.metrics-red {
  border-radius: 20px;
  background: rgba(215, 0, 0, 0.25);
  margin-left: 20px;
  padding: 5px 20px;

  .metrics-text {
    color: #D70000 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.company {
  min-height: 50px;

  .name {
    color: #262626;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
  }

  .btn-options {
    border-radius: 5px !important;
    border: 2px solid #262626 !important;
    background: transparent !important;
    width: 100px !important;
    text-align: center !important;
  }

  .options {
    border-radius: 8px !important;
    width: 230px !important;
  }

  .btn-options-text {
    color: #262626 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
  }
}


hr {
  margin-top: 0 !important;
  background: #c0c0c0;
}

h3 {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.report {
  .btn-options {
    border-radius: 5px !important;
    //border: 2px solid #262626 !important;
    background: var(--navy-blue) !important;
    width: fit-content !important;
    text-align: center !important;
    height: 33px;
    margin-right: 20px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }

  .options {
    border-radius: 5px !important;
    width: fit-content !important;
  }

  .btn-options-text {
    color: #FFFFFF !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
  }
}
</style>
