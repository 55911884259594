<template>
  <b-modal
    id='surveyRecommendation'
    size='lg'
    ref='surveyRecommendation'
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
  >
    <section class='modal-font-family'>
      <section class='mb-4'>
        <p class='survey-title'>
          Pesquisa de Satisfação
        </p>
      </section>

      <section>
        <p class='survey-sub-title'>
          Em uma escala de 0 a 10, o quanto você recomendaria a HOFácil para outras pessoas?
        </p>
      </section>

      <section class='d-flex justify-content-between mb-3'>
        <section>
          <span class='text-would-recommend'>NÃO RECOMENDARIA</span>
        </section>

        <section>
          <span class='text-would-recommend'>RECOMENDARIA BASTANTE</span>
        </section>
      </section>

      <section class='scales mb-5 w-100'>
        <section v-for='(scale, i) in scales' :key='i'>
          <section
            :class="selectedScale === scale.scale ? 'selected-scale' : 'scale'"
            @click='selectScale(scale.scale)'
            :style="selectedScale === scale.scale ? ``: `background: ${scale.color}`"
          >
            <span class='label'>{{ scale.scale }}</span>
          </section>
        </section>
      </section>

      <section class='float-right'>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
        >
          <base-button
            class='btn_export_modal'
            @click='submitSurvey()'
          >
            <span class='label'>Enviar</span>
          </base-button>
        </b-overlay>
      </section>
    </section>
  </b-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SurveyRecommendation',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      scales: [
        {
          scale: 0,
          color: '#B72224;'
        },
        {
          scale: 1,
          color: '#D52029;'
        },
        {
          scale: 2,
          color: '#E95223;'
        },
        {
          scale: 3,
          color: '#EA6F22;'
        },
        {
          scale: 4,
          color: '#F6A726;'
        },
        {
          scale: 5,
          color: '#FDC729;'
        },
        {
          scale: 6,
          color: '#EBDB0A;'
        },
        {
          scale: 7,
          color: '#E5E044;'
        },
        {
          scale: 8,
          color: '#C2D234;'
        },
        {
          scale: 9,
          color: '#AEC93C;'
        },
        {
          scale: 10,
          color: '#66B44E;'
        }
      ],
      selectedScale: null,
      loading: false,
    }
  },
  created() {
    this.survey.body = {
      scale: null
    }
  },
  validations: {
    survey: {
      type: { required },
      body: {
        scale: { required }
      }
    }
  },
  computed: {
    ...mapGetters('surveys', {
      survey: 'getSurvey'
    })
  },
  methods: {
    ...mapActions('surveys', {
      handleCreateSurvey: 'handleCreateSurvey',
      resetSurvey: 'resetSurvey'
    }),
    selectScale(scale) {
      this.selectedScale = scale
      this.survey.body.scale = this.selectedScale
    },
    closeModal() {
      this.$refs.surveyRecommendation.hide()
    },
    async submitSurvey() {
      if (this.survey.body.scale == null || this.survey.body.scale == 'null') {
        this.$bvToast.toast(`Selecione uma escala de 0 a 10 para enviar!`, {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 7000
        })
        return
      }
      this.loading = true;
      this.survey.type = 'recommendation-surveys'
      const response = await this.handleCreateSurvey()
      this.loading = true;
      let message = ''
      let variant = ''

      if (response.message) {
        const sessionId = localStorage.getItem('sessionId')
        const user = (await this.$api.get(`users/${sessionId}`)).data
        await this.$store.dispatch('user/updateUser', user)
        message = 'Pesquisa enviada com sucesso'
        variant = 'success'
      }
      if (response.errors) {
        message = 'Erro ao enviar pesquisa'
        variant = 'danger'
      }

      this.closeModal()

      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })

      await this.resetSurvey()
      this.loading = false;
    }
  }
}
</script>

<style lang='scss' scoped>
.survey-title {
  color: #262626;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.survey-sub-title {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.text-would-recommend {
  color: #262626;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
}

.scales {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  .scale {
    cursor: pointer;
    display: flex;
    width: 45px;
    height: 45px;
    padding: 9.5px 14.5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    opacity: 0.8;
    gap: 10px;

    .label {
      text-align: center;
      align-self: center;
      color: #FFFFFF !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
  }
}

.selected-scale {
  background:  var(--navy-blue)!important;
  cursor: pointer;
  display: flex;
  width: 45px;
  height: 45px;
  scale: 1.4;
  padding: 9.5px 14.5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 10px;
  .label {
    text-align: center;
    align-self: center;
    color: #FFFFFF !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
}
</style>
